module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="top">\r\n        <div class="container">\r\n            <div class="left">\r\n                <a href="javascript:;" class="logo">\r\n                    <img src="' +
((__t = ( require('./images/footer-logo.png') )) == null ? '' : __t) +
'" alt="">\r\n                </a>\r\n                <div class="href">\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/footer-icon1.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/footer-icon2.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/footer-icon3.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/footer-icon4.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/footer-icon5.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                </div>\r\n            </div>\r\n            <div class="right">\r\n                <dl>\r\n                    <dt>\r\n                        TIRE CATEGORIES\r\n                    </dt>\r\n                    <dd><a href="javascript:;">Performance Tires</a></dd>\r\n                    <dd><a href="javascript:;">Light Truck/SUV/CUV Tires</a></dd>\r\n                    <dd><a href="javascript:;">Racing Tires</a></dd>\r\n                </dl>\r\n                <dl>\r\n                    <dt>\r\n                        POPULAR PRODUCTS\r\n                    </dt>\r\n                    <dd><a href="javascript:;">Dakar M/T</a></dd>\r\n                    <dd><a href="javascript:;">Dakar R/T</a></dd>\r\n                    <dd><a href="javascript:;">Racing King</a></dd>\r\n                    <dd><a href="javascript:;">Pioneer M/T</a></dd>\r\n                    <dd><a href="javascript:;">Predator M/T</a></dd>\r\n                    <dd><a href="javascript:;">All Terrain III</a></dd>\r\n                    <dd><a href="javascript:;">Dakar R/TX</a></dd>\r\n                </dl>\r\n                <dl>\r\n                    <dt>\r\n                        HELP AND SUPPORT\r\n                    </dt>\r\n                    <dd><a href="javascript:;">About us</a></dd>\r\n                    <dd><a href="javascript:;">Contact us</a></dd>\r\n                    <dd><a href="javascript:;">Warranty</a></dd>\r\n                    <dd><a href="javascript:;">Careers</a></dd>\r\n                    <dd><a href="javascript:;">Dealer Login</a></dd>\r\n                    <dd><a href="javascript:;">Gallery</a></dd>\r\n                </dl>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="bottom">\r\n        <p>Copyright © 2023 Homeway Tire All Rights Reserved   Homeway Tire is proudly powered by dobaqu Website construction Privacy Policy</p>\r\n    </div>\r\n</footer>';

}
return __p
}