import './index.scss'


$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
    $(".open-menu").toggleClass("active");
    $(".function .btn").css("display", "flex");
    $(".function .search").hide()
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})
$(".href-s").click(function() {
    $(".function .btn").hide();
    $(".function .search").show()
})
$("header .nav li").hover(function() {
    $(this).find(".xiala").stop().show();  
    $(".select").removeClass("active");  
},function() {
    $(this).find(".xiala").stop().hide();  
})
$("header .nav li:first").hover(function() {
    $(".select").addClass("active");  
})
$('.select').on('mouseleave',function(event){
    event.preventDefault();
    $(".select").removeClass("active");  
})

// $(".select-nav dd").hover(function() {
//     console.log($(this).attr("id"));
//     let ddid = $(this).attr("id");
//     $(".select .right img").each(function(){
//         if($(this).attr("data",ddid)){
//             console.log($(this));
//         }
//     })
// })
$(".select .right img").eq(0).addClass("active")
$(".select-nav dd").hover(function() {
    let ddid = $(this).attr("id");
    $(".select .right").find("." + ddid).addClass("active").siblings().removeClass("active")
})